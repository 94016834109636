<template>
  <div class="flex flex-col gap-y-12 pt-10 bg-white">
    <!-- Header -->
    <div class="bg-dokBlue-lighter py-8 px-2">
      <div class="container mx-auto">
        <div
          class="grid gap-x-4 gap-y-2 grid-cols-1 lg:grid-cols-2 items-center"
        >
          <div class="flex flex-col">
            <h1 class="text-lg lg:text-6xl font-EffraM text-black">
              Trouvez le plan qui vous aidera à vous développer
            </h1>
            <span class="text-sm lg:text-2xl text-black font-EffraR"
              >Nos options tarifaires sont conçues pour convenir aux différents
              professionnels de santé.</span
            >
          </div>
          <img src="/img/mask.webp" alt="dok.ma" class="w-full lg:w-auto" />
        </div>
      </div>
    </div>
    <!-- End  Header -->

    <!-- Offre de lancement -->

    <div class="container mx-auto">
      <div class="flex flex-col mb-5">
        <h1 class="text-2xl font-EffraM">Offre de lancement dok Pro Premium</h1>
        <!-- <span class="font-EffraR text-base text-gray-600"
          >Valable jusqu'au 31 décembre 2021</span
        > -->
      </div>
      <div class="flex flex-col gap-y-0">
        <div
          class="
            bg-dokOrange-ultra
            rounded-t-lg
            text-white
            h-[48px]
            flex
            items-center
            justify-center
          "
        >
          <div class="flex flex-row items-start gap-x-2">
            <span>
              <svg
                width="18"
                height="18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#a)">
                  <path
                    d="M5.25 5.25h.008m10.184 4.807-5.377 5.378a1.5 1.5 0 0 1-2.122 0L1.5 9V1.5H9l6.443 6.442a1.5 1.5 0 0 1 0 2.115z"
                    stroke="#fff"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="a">
                    <path fill="#fff" d="M0 0h18v18H0z" />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span class="font-EffraR">Offre de lancement dok Pro Premium</span>
          </div>
        </div>
        <div
          class="
            grid grid-cols-1
            lg:grid-cols-3
            rounded-b-lg
            border border-solid border-gray-300
          "
        >
          <div
            class="
              text-center text-2xl
              flex
              gap-x-2
              items-center
              justify-center
              h-[71px]
            "
          >
            <span class="font-EffraR text-dokOrange-ultra">3 Mois</span>
            <span class="text-black font-EffraM">600 MAD/mois</span>
          </div>
          <div
            class="
              text-center text-2xl
              flex
              gap-x-2
              items-center
              justify-center
              bg-dokOrange-light
              h-[71px]
              border-0 border-l-2 border-r-2 border-solid border-dokOrange-ultra
            "
          >
            <span class="font-EffraR text-dokOrange-ultra">1 année</span>
            <span class="text-black font-EffraM">400 MAD/mois</span>
            <!-- <button>Choisir</button> -->
          </div>
          <div
            class="
              text-center text-2xl
              flex
              gap-x-2
              items-center
              justify-center
              h-[71px]
            "
          >
            <span class="font-EffraR text-dokOrange-ultra">6 Mois</span>
            <span class="text-black font-EffraM">500 MAD/mois</span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Offre de lancement -->

    <!-- Pricing -->
    <div class="container mx-auto flex flex-col gap-y-16">
      <div class="flex flex-col">
        <h1 class="text-2xl font-EffraM">
          Tarification et fonctionnalité dok Pro
        </h1>
        <span class="font-EffraR text-base text-gray-600"></span>
      </div>

      <praticien />
      <!-- <pharmacie /> -->
      <!-- <infirmier /> -->
    </div>
    <!-- End Pricing -->

    <dokFooter />
  </div>
</template>

<script>
  const praticien = () => import("./components/praticien.vue");
  const pharmacie = () => import("./components/pharmacie.vue");
  const infirmier = () => import("./components/infirmier.vue");
  const dokFooter = () => import("@/views/home/layouts/footer");

  export default {
    components: { praticien, pharmacie, infirmier, dokFooter },
  };
</script>

<style lang="scss">
.pricing-item {
  background-color: #fff;
}

.pricing-item:nth-child(2n + 1) {
  background-color: #f9f9f9;
}
</style>
